import React, { Component } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ServiceCenter from './components/ServicesCenter'
import ServicesCenter1 from './components/ServicesCenter1';

export class AppRouter extends Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={< ServiceCenter />}></Route>
                    <Route path='/1' element={< ServicesCenter1 />}></Route>
                </Routes>
            </BrowserRouter>
        )
    }
}
export default AppRouter;