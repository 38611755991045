
import './App.css';
import AppRouter from './AppRouter'
import React, { Component } from 'react'


global.loader = <div className='overlay'><div className="spinner-border" role="status"><span className="visually-hidden">Loading...</span></div></div>;

class App extends Component {
  render() {
    return (
      <div className="App">
        < AppRouter />
      </div>
    );
  }
}

export default App;
