import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://127.0.0.1:8000/';

class ApiService {
    async getCall(param) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'ABC'
        }
        const data = param.data;
        return axios.get(API_URL + param.END_POINT, data, {
                headers: headers
            })
            .then((response) => {
                return { status: response.data.status, message: response.data.message, data: response.data.data };
            })
            .catch((error) => {
                return { status: error.status, message: error.message, data: error.data };
            })
    }

    async postCall(param) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'ABC'
        }
        const data = param.data;
        return axios.post(API_URL + param.END_POINT, data, {
                headers: headers
            })
            .then((response) => {
                return { status: response.data.status, message: response.data.message, data: response.data.data };
            })
            .catch((error) => {
                return { status: error.status, message: error.message, data: error.data };
            })
    }
}
export default new ApiService();