import React, { Component } from 'react'
import '../assets/css/main.css'

class ServicesCenter1 extends Component {
    render() {
        return (
            <div>
                <h4>Hello World</h4>
            </div>
        )
    }
}
export default ServicesCenter1;