import React, { Component } from 'react'
import Select from 'react-select'
import { Accordion } from 'react-bootstrap';
import '../assets/css/main.css'
import '../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
import ApiService from '../services/ApiService'

class ServiceCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            services_list: [
                { "value": 'Microwave oven', "label": "Microwave oven" },
                { "value": 'Washing Machine', "label": "Washing Machine" },
                { "value": 'Fridge/ Refrigerator', "label": "Fridge/ Refrigerator" },
                { "value": 'Air Conditioner', "label": "Air Conditioner" },
                { "value": 'All electronics goods', "label": "All electronics goods" }
            ],
            state_list: [],
            city_list: [],
            search_result: [],
            inputs: {
                state_id: '',
                city_id: '',
                service: ''
            },
            days: {
                1: 'Mon',
                2: 'Tue',
                3: 'Wed',
                4: 'Thu',
                5: 'Fri',
                6: 'Sat',
                7: 'Sun'
            },
            spinner: false
        };
        this.getState = this.getState.bind(this);
        this.getCity = this.getCity.bind(this);
        this.changeHandleService = this.changeHandleService.bind(this);
        this.changeHandleState = this.changeHandleState.bind(this);
        this.changeHandleCity = this.changeHandleCity.bind(this);
        this.Search = this.Search.bind(this);
    }

    componentDidMount() {
        this.getState();
    }

    async getState() {
        this.setState({ spinner: true });
        var param = {
            END_POINT: 'get-state',
            data: {}
        };
        var state_data = await ApiService.getCall(param);
        var state_list = [];
        if (state_data.status === 200) {
            state_data.data.forEach(item => {
                state_list.push({ value: item.value, label: item.label });
            });
        }
        this.setState({ state_list: state_list });
        this.setState({ spinner: false });
    }

    async getCity(queryString = '') {
        this.setState({ spinner: true });
        var param = {
            END_POINT: 'get-city?' + queryString,
            data: {}
        };
        var city_data = await ApiService.getCall(param);
        var city_list = [];
        if (city_data.status === 200) {
            city_data.data.forEach(item => {
                city_list.push({ value: item.value, label: item.label });
            });
        }
        this.setState({ city_list: city_list });
        this.setState({ spinner: false });
    }

    async getData() {
        this.setState({ spinner: true });
        var param = {
            END_POINT: 'get-service-center',
            data: this.state.inputs
        };
        var get_data = await ApiService.postCall(param);
        var search_result = [];
        if (get_data.status === 200) {
            search_result = get_data.data
            if (search_result.length === 0) {
                alert('No Data Found!!');
            }
        }
        this.setState({ search_result: search_result });
        this.setState({ spinner: false });
    }

    changeHandleService(val) {
        var inputs = this.state.inputs;
        inputs.service = val.value;
        this.setState(inputs);
    }
    changeHandleState(val) {
        var inputs = this.state.inputs;
        inputs.state_id = val.value;
        this.setState(inputs);
        this.getCity('state_id=' + val.value);
    }

    changeHandleCity(val) {
        var inputs = this.state.inputs;
        inputs.city_id = val.value;
        this.setState(inputs);
    }

    Search = (event) => {
        var inputs = this.state.inputs;
        if (event.target.name === "search") {
            inputs[event.target.name] = event.target.value;
        }

        if (event.target.name === "search_submit") {
            this.getData()
        }
    }

    render() {
        return (
            <div className="s007">
                {this.state.spinner ? global.loader : ''}
                <div className='main-form'>
                    <div className="inner-form">
                        <div className="text-center mb-5">
                            <img src="https://shop2core.in/wp-content/uploads/2019/08/Shop2Core.png" />
                            <p className="h4 text-white mt-3">Locate a service center near you. Find the nearest service centers for the following location servicess:</p>
                        </div>
                        <div className="basic-search mt-5">
                            <div className="input-field">
                                <div className="icon-wrap">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={20} height={20} viewBox="0 0 20 20">
                                        <path d="M18.869 19.162l-5.943-6.484c1.339-1.401 2.075-3.233 2.075-5.178 0-2.003-0.78-3.887-2.197-5.303s-3.3-2.197-5.303-2.197-3.887 0.78-5.303 2.197-2.197 3.3-2.197 5.303 0.78 3.887 2.197 5.303 3.3 2.197 5.303 2.197c1.726 0 3.362-0.579 4.688-1.645l5.943 6.483c0.099 0.108 0.233 0.162 0.369 0.162 0.121 0 0.242-0.043 0.338-0.131 0.204-0.187 0.217-0.503 0.031-0.706zM1 7.5c0-3.584 2.916-6.5 6.5-6.5s6.5 2.916 6.5 6.5-2.916 6.5-6.5 6.5-6.5-2.916-6.5-6.5z" />
                                    </svg>
                                </div>
                                <input id="search" type="text" name='search' onChange={this.Search} placeholder="Search..." />
                                <div className="result-count">
                                    <span>{this.state.search_result.length} </span>results</div>
                            </div>
                        </div>
                        <div className="advance-search">
                            <div className="row">
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <Select options={this.state.services_list} placeholder="Select Service" onChange={this.changeHandleService} name="service" />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <Select options={this.state.state_list} placeholder="Select State" onChange={this.changeHandleState} name="state" />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <Select options={this.state.city_list} placeholder="Select City" onChange={this.changeHandleCity} name="city" />
                                    </div>
                                </div>
                                <div className='col-md-2'>
                                    <div className='form-group'>
                                        <button onClick={this.Search} name="search_submit" className="btn-search">Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            this.state.search_result.length > 0 ?
                                <div className='advance-search resume-search-result mt-5'>
                                    <div className='row'>
                                        {
                                            this.state.search_result.map((item, index) => {
                                                return (
                                                    <div className='col-md-12' key={index}>
                                                        <div className="card bg-light mt-3 profile">
                                                            <div className="card-header d-flex">
                                                                <div className="left">
                                                                    <img src="https://cdn-icons-png.flaticon.com/512/4781/4781517.png" className="image" />
                                                                </div>
                                                                <div className="right">
                                                                    <h5>{item.center_name}</h5>
                                                                    <h6><b>Address : </b> {item.address}, {item.city.name} , {item.state.name}</h6>
                                                                    <h6><b>Email : </b> <a href={"mailto:" + item.email}>{item.email}</a></h6>
                                                                    <h6><b>Phone : </b> <a href={"tel:+" + item.contact_no}>{item.contact_no}</a>,
                                                                        <a href={"tel:+" + item.alt_contact_no}>{item.alt_contact_no}</a></h6>
                                                                    <h6><b>Services : </b> {item.services}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="card-body">
                                                                <Accordion>
                                                                    <Accordion.Item eventKey="0">
                                                                        <Accordion.Header>Working Day & Hours</Accordion.Header>
                                                                        <Accordion.Body>
                                                                            <table className="table table-bordered table-striped">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Days</th>
                                                                                        <th>Open At</th>
                                                                                        <th>Close At</th>
                                                                                        <th>Closed</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        item.custom.working_days.map((working, i) => {
                                                                                            return (
                                                                                                <tr key={i}>
                                                                                                    <td>{this.state.days[working.day]}</td>
                                                                                                    <td>{working.open_at}</td>
                                                                                                    <td>{working.close_at}</td>
                                                                                                    <td>{working.closed}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </Accordion.Body>
                                                                    </Accordion.Item>
                                                                </Accordion>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                : ''
                        }
                    </div>
                </div >
            </div >

        )
    }
}
export default ServiceCenter;